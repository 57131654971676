<nz-card [nzExtra]="switchStyle">
  <div nz-row [nzGutter]="16" id="filter-container">
    <div nz-col nzFlex="auto">
      <nz-select
        style="width: 50%"
        nzShowSearch
        [(ngModel)]="secondType"
        (ngModelChange)="showUploadIcon()"
        nzPlaceHolder="Select type"
      >
        <nz-option nzLabel="PO" nzValue="PO"></nz-option>
        <nz-option nzLabel="PI" nzValue="PI"></nz-option>
        <nz-option nzLabel="Payment slip" nzValue="paymentSlip"></nz-option>
        <nz-option nzLabel="Tax invoice" nzValue="taxInvoice"></nz-option>
        <nz-option
          nzLabel="Final payment slip"
          nzValue="finalPaymentSlip"
        ></nz-option>
        <nz-option nzLabel="Other" nzValue="other"></nz-option>
      </nz-select>
    </div>
  </div>
  <nz-divider></nz-divider>
  <nz-spin [nzSpinning]="isSpinning">
    <div style="height: 350px; overflow-y: scroll">
      <nz-upload
        [nzAction]="nzAction"
        nzListType="picture"
        [(nzFileList)]="files"
        [nzDisabled]="!secondType"
        [nzShowUploadList]="icons"
        [nzMultiple]="true"
        [nzBeforeUpload]="beforeUpload"
        [nzShowButton]="secondType"
        [nzPreview]="handlePreview"
        [nzRemove]="handleRemove"
        (nzChange)="stateChange($event)"
        [ngClass]="{ 'upload-list-inline': displayStyle === 'inline' }"
        [nzCustomRequest]="customUploadReq"
        [nzHeaders]="setMediaUploadHeaders"
      >
        <button nz-button style="padding: 0 8px">
          <i nz-icon nzType="upload"></i>Upload
        </button>
      </nz-upload>

      <nz-modal
        [nzVisible]="previewVisible"
        [nzContent]="modalContent"
        [nzFooter]="null"
        nzClosable="false"
        (nzOnCancel)="previewVisible = false"
      >
        <ng-template #modalContent>
          <img
            alt="order image preview"
            [src]="previewImage"
            [ngStyle]="{ width: '100%' }"
          />
        </ng-template>
      </nz-modal>
    </div>
  </nz-spin>
</nz-card>

<ng-template #switchStyle>
  <!-- <nz-switch [(ngModel)]="isInlineView" nzCheckedChildren="Inline" nzUnCheckedChildren="List"></nz-switch> -->
  <nz-radio-group [(ngModel)]="displayStyle">
    <label nz-radio-button nzValue="inline"
      ><i nz-icon nzType="table"></i
    ></label>
    <label nz-radio-button nzValue="list"><i nz-icon nzType="menu"></i></label>
  </nz-radio-group>
</ng-template>
