import { LoginGuardService } from './layouts/service/guards/confirmation/login-guard.service';
import { AuthguardService } from './layouts/service/authguard.service';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: 'account/signin',
    loadChildren: () =>
      import('./layouts/unauthorized/signin/signin.module').then(
        (mod) => mod.SigninModule
      ),
    canActivate: [LoginGuardService],
  },
  {
    path: '',
    loadChildren: () =>
      import('./layouts/authorized/authorized.module').then(
        (mod) => mod.AuthorizedModule
      ),
    canActivate: [AuthguardService],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
