import { AgmCoreModule } from '@agm/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StorageModule } from '@ngx-pwa/local-storage';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TokenService } from './layouts/service/token.service';
import { SelectionExportComponent } from './layouts/shared/components/selection-export/selection-export.component';
import { ImageSlipUploadModule } from './layouts/shared/image-slip-upload/image-slip-upload.module';
@NgModule({
  declarations: [AppComponent, SelectionExportComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    AngularSvgIconModule.forRoot(),
    // NgbModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAf0aX6Jgz6HbqvFIhI9BejpKdx3tVhjTk&libraries=places',
      language: 'en',
    }),
    StorageModule.forRoot({ IDBNoWrap: true }),
    NzMessageModule,
    NzButtonModule,
    ImageSlipUploadModule,
    NzCheckboxModule,
    NzCardModule,
    NzGridModule,
    NzModalModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenService, multi: true },
    { provide: NZ_I18N, useValue: en_US },
  ],
  bootstrap: [AppComponent],
  entryComponents: [SelectionExportComponent],
})
export class AppModule {}
