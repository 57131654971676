import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageSlipUploadComponent } from './image-slip-upload.component';
import { FormsModule } from '@angular/forms';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSwitchModule } from 'ng-zorro-antd/switch';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NzCardModule,
    NzUploadModule,
    NzButtonModule,
    NzIconModule,
    NzDividerModule,
    NzSelectModule,
    NzSpinModule,
    NzModalModule,
    NzSwitchModule,
    NzRadioModule,
    NzGridModule,
  ],
  declarations: [ImageSlipUploadComponent],
  exports: [ImageSlipUploadComponent],
  entryComponents: [ImageSlipUploadComponent],
})
export class ImageSlipUploadModule {}
