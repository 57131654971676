import { PermissionService } from './permission.service';
import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { StorageService } from './storage.service';
import { Observable, of } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';
@Injectable({
  providedIn: 'root',
})
export class AuthguardService implements CanActivate {
  constructor(
    private storageService: StorageService,
    private permissionService: PermissionService,
    private router: Router,
    private messageService: NzMessageService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    let url =
      state.url.indexOf('/') !== -1
        ? state.url.substring(1, state.url.length)
        : state.url;

    const duplicateUrl = url;

    if (url.indexOf('list') !== -1 || url.indexOf('add') !== -1) {
      url = url.substring(0, url.indexOf('/'));
    }

    if (duplicateUrl.indexOf('work_order?') !== -1) {
      url = duplicateUrl;
    }

    if (url === 'user') {
      url = 'user:list';
    } else if (url === 'role') {
      url = 'role:list';
    } else if (url === 'menu') {
      url = 'menu:list';
    } else if (url === 'dept') {
      url = 'dept:list';
    } else if (url === 'session') {
      url = 'session:list';
    } else if (url.indexOf('log?') !== -1) {
      url = 'log:list';
    } else if (url === 'point') {
      url = 'point:list';
    } else if (url === 'building') {
      url = 'building:list';
    } else if (url === 'agreement') {
      url = 'agreement:list';
    } else if (url.indexOf('work_order?') !== -1) {
      url = 'work_order:list';
    }

    if (!this.storageService.isAuthenticated()) {
      this.router.navigate(['/account/signin']);
      return of(false);
    } else if (
      this.permissionService.hasPermission(url) ||
      url === 'building:list' ||
      url === 'building/manage'
    ) {
      return of(true);
    } else {
      return of(true);
      // this.spinnerService.showMessage(this.snackBar, 'Access denied');
      // this.router.navigate(['/building']);
    }
  }
}
