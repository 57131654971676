import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { NzModalService } from 'ng-zorro-antd/modal';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(private http: HttpClient, private modalService: NzModalService) {}

  openUpload(payment: any, content: any) {
    const modal = this.modalService.create({
      nzTitle: '',
      nzContent: content,

      nzComponentParams: {
        payment,
      } as any,
      nzFooter: null,
      nzClosable: false,
      nzBodyStyle: {
        padding: '0',
      },

      nzWidth: '60vw',
      nzStyle: { 'min-width': '520px' },
    });

    return modal;
  }

  findAll(type: string, id: number) {
    const params = new HttpParams().set('type', type).set('id', id.toString());
    return this.http.get(`${environment.API_URL}/file`, { params });
  }

  delete(id: number, type: string, key: string) {
    return this.http.post(
      `${environment.API_URL}/file/new/del/${type}/${id}`,
      key
    );
  }

  findAllImageBy(type: string, id: number) {
    const params = new HttpParams()
      .set('id', id.toString())
      .set('type', type.toString());
  }

  deleteOrderImage(file, secondType: string) {
    const params = new HttpParams()
      .set('id', file.id)
      .set('url', file.url)
      .set('secondType', secondType);
    return this.http.delete(
      `${environment.API_URL}/invoice-vendor-payment/order/image`,
      {
        params,
      }
    );
  }

  uploadFiles(files: any) {
    return this.http.post(
      `${environment.API_URL}/file/upload/vendorinvoicepayment/-1`,
      files
    );
  }
}
