import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-file-view',
  templateUrl: './file-view.component.html',
  styleUrls: ['./file-view.component.scss'],
})
export class FileViewComponent implements OnInit {
  @Input() isImage: boolean;
  @Input() fileName: string;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit() {}

  photoURL() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.fileName);
  }
}
